<template>
    <v-card hover>
        <v-card-title style="height:70px; padding:30px; font: normal normal 600 20px/20px Apple SD Gothic Neo;">
            <v-row class="ma-0 pt-0">
                <v-col>{{ agreeTitle }}</v-col>
                <v-col class="text-right pt-0">
                    <v-btn icon @click="closePopup">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text style="padding:30px 30px 20px 30px; font: normal normal normal 20px/22px Apple SD Gothic Neo; letter-spacing: -0.6px; color: #333333;">
            <hr/>
            <iframe :src="agreeSrc" style="width:100%; border:0px; height:500px;"></iframe>
        </v-card-text>
        <v-card-actions class="ma-0 pt-0 pl-0 pr-0 pb-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-8 pb-5 pt-0 text-right">
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="closePopup()" autofocus><span
                        style="font: normal normal bold 18px/21px Apple SD Gothic Neo;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: ['agreeType'],
    data: () => ({}),
    computed: {
        ...mapGetters({
            popupModal: 'popupModal',
            popupType: 'popupType',
            popupData: 'popupData',
        }),
        agreeTitle() {
            if (this.agreeType == 'SERVICE') {
                return '서비스 이용약관'
            } else if (this.agreeType == 'PRIVATE') {
                return '개인정보 취급방침'
            } else if (this.agreeType == 'RECORD') {
                return '통화 녹취 개인정보 수집 및 이용동의'
            }

            return this.agreeType
        },
        agreeSrc() {
            if (this.agreeType == 'SERVICE') {
                return 'https://api.brocallee.com:27056/v2/agree/doc/SERVICE'
            } else if (this.agreeType == 'PRIVATE') {
                return 'https://api.brocallee.com:27056/v2/agree/doc/PRIVATE'
            } else if (this.agreeType == 'RECORD') {
                return 'https://api.brocallee.com:27056/v2/agree/doc/RECORD'
            }

            return ''
        }
    },
    mounted() {
    },
    methods: {
        closePopup() {
            this.$emit('close')
        },

    }
}
</script>
